import React, { Component } from "react";
import SongCard from "./SongCard";
import moment from "moment";

class SongList extends Component {
  render() {
    return (
      <div>
        {this.props.songs.map(song => {
          return (
            <SongCard
              key={song.url + song.played_at}
              name={song.name}
              artist={song.artist}
              played_at={moment(song.played_at).fromNow()}
              url={song.url}
              artwork={song.artwork}
            ></SongCard>
          );
        })}
      </div>
    );
  }
}

export default SongList;
