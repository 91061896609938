import React, { Component } from "react";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";
import SongList from "./SongList";
import Select from "./Select";


class Main extends Component {
  state = {
    user: null,
    songs: [],
    sortBy: "played",
    refreshHandler: null
  };

  render() {
    let songs = this.sortSongs(this.state.songs);

    //if logged out
    if (!localStorage.jwt){
      return (
        <div>
          <Header></Header>
          <div className="container">
            <p>Not logged in.</p>
          </div>
          <Footer></Footer>
        </div>
      );
    }
      
    return (
      <div className="container">
        <Header></Header>
        {this.state.songs.length === 0? "Loading songs..." : <Select
          sortBy={this.state.sortBy}
          handleChange={e => {
            this.setState({ sortBy: e });
          }}
        ></Select>}
        
        <SongList songs={this.state.songs}></SongList>
        <Footer ></Footer>
      </div>
    );
    
  }

  componentDidMount() {

    //first call
    this.getRecentTracks();

    //clear any existing interval
    if(this.state.refreshHandler) window.clearInterval(this.state.refreshHandler);

    //polling, re-check every 10 seconds
    let refreshHandler = window.setInterval(() => {
      this.getRecentTracks();
    }, 10000);

    //store the handler
    this.setState({refreshHandler});

  }

  getRecentTracks() {
    //handle logged out
    if (!localStorage.jwt) {
      this.reauthenticate();
      return;
    }

    const application = this;

    //get recent tracks from the api

    axios
      .get(`https://spotify.richardruff.co.uk/api/recent`, {
        headers: { authorization: "Bearer " + localStorage.jwt }
      })
      .then(res => {
        let songs = res.data;
        this.setState({
          songs
        });
      })
      .catch(() => {
        this.reauthenticate();
      })

      //handle api failure by re-running auth flow
      .catch(function(err) {
        application.reauthenticate();
      });
  }

  reauthenticate() {
    axios.get("https://spotify.richardruff.co.uk/api/login").then(res => {
      window.location.replace(res.data.redirect_url);
    });
  }

  sortSongs(songs) {
    if (this.state.sortBy === "title") {
      let sortedSongs = songs.sort((a, b) => {
        return a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1;
      });
      return sortedSongs;
    }

    if (this.state.sortBy === "played") {
      let sortedSongs = songs.sort((a, b) => {
        return a.played_at < b.played_at ? 1 : -1;
      });
      return sortedSongs;
    }

    if (this.state.sortBy === "artist") {
      let sortedSongs = songs.sort((a, b) => {
        return a.artist.toUpperCase() > b.artist.toUpperCase() ? 1 : -1;
      });
      return sortedSongs;
    }
  }
}

export default Main;
