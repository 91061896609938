import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/free-solid-svg-icons";

const Header = () => {
  return (
    <div className="container">
      <h1>
        <FontAwesomeIcon icon={faHistory} />
        &nbsp;Spotify History
      </h1>
    </div>
  );
};

export default Header;
