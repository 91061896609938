import React, { Component } from "react";
import Header from "./Header";
import queryString from "query-string";
import axios from "axios";

class Callback extends Component {
  state = {};
  render() {
    const query = queryString.parse(this.props.location.search);
    const authorization_code = query.code;

    axios
      .get(
        `https://spotify.richardruff.co.uk/api/auth?code=${authorization_code}`
      )
      .then(res => {
        localStorage.setItem("jwt", res.data.token);
        this.props.history.replace("./");
      });

    return (
      <div>
        <Header />
        <div className="container">
          <p>Please wait...</p>
        </div>
      </div>
    );
  }
}

export default Callback;
