import { Link } from "react-router-dom";
import React, { Component } from 'react';

class Footer extends Component {
    state = {  }
    render() { 
        return ( <div className="footer row">
            <div className="col">
                <Link to="./">Recent Songs</Link> | <Link to="./privacy">Privacy</Link>
            </div>
            &nbsp;
            <div className="col footerRight">
                Copyright &copy; <a href="https://employ.richardruff.co.uk">Richard Ruff</a> {new Date().getFullYear()}
            </div>


        </div> );
    }
}
 
export default Footer;