import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

const useStyles = makeStyles(theme => ({
  root: {
    marginBottom: "10px",
    // maxHeight: "125px",
    overflow: "hidden"
  }
}));

const coverStyle = {
  width: 125,
  height: 125,
  marginRight: "1em",
  float: "left"
};

const imageStyle = {
  width: "100%",
  height: "100%"
};

const detailStyle = {
  padding: "1em"
};

export default function SongCard(props) {
  const classes = useStyles();

  return (
    <div>
      <a href={props.url} className="nostyle">
        <Paper className={classes.root}>
          <div style={coverStyle}>
            <img style={imageStyle} src={props.artwork} loading="lazy" alt="" />
          </div>

          <div style={detailStyle}>
            <Typography component="h5" variant="h5">
              <div className="truncate">{props.name}</div>
            </Typography>
            <Typography variant="subtitle1" color="textSecondary">
              <div className="truncate">{props.artist}</div>
            </Typography>
            <Typography variant="subtitle2" color="textSecondary">
              <div className="truncate">{props.played_at}</div>
            </Typography>
          </div>
        </Paper>
      </a>
    </div>
  );
}
