import React, { Component } from "react";
import Header from "./Header";
import Footer from "./Footer";
import ScrollToTop from "./ScrollToTop";

class Privacy extends Component {
  state = {};
  render() {
    return (
      <div class="container">
        <ScrollToTop></ScrollToTop>
        <Header></Header>
        <h1>Privacy Policy</h1>
        <h3>Ownership &amp; Location</h3>
        <p>
          This site is a personal project of, and is operated by, Richard Ruff.{" "}
          Should you have any concerns regarding this privacy notice, you can
          contact him at{" "}
          <a href="mailto:spotify.privacy@richardruff.co.uk">
            spotify.privacy@richardruff.co.uk
          </a>
          . Hosting facilities are provided by{" "}
          <a href="https://www.linode.com/">Linode LLC</a> and are situated in
          London, United Kingdom.
        </p>
        <h3>Data we collect</h3>
        <p>
          We may collect the following information when you authorise us to do
          so using the&nbsp;
          <a href="https://accounts.spotify.com">Spotify Accounts Service</a>.
          The legal basis for pocessing this information is consent.
        </p>
        <ul>
          <li>Your Spotify user ID.</li>
          <li>The e-mail address associated with your Spotify account.</li>
          <li>Any other public information about your Spotify account.</li>
          <li>
            An access token and authorisation code, used to make further
            information requests about your Spotify account from the Spotify
            Accounts Service.
          </li>
          <li>Information about songs you have listened to.</li>
          <li>
            Information about your playlists, for the purposes of creating a
            playlist from your history.
          </li>
        </ul>
        <p>We may also collect the following general informaiton:</p>
        <li>The time you signed up to this website.</li>
        <li>
          The time(s) you accessed this website, and the associated IP
          address(es).
        </li>
        <h3>Cookies</h3>
        <p>
          This website may use Strictly Necessary Cookies for the purpose of
          accessing your Spotify account with the Spotify accounts service. This
          website also uses a JSON Web Token or Cookie to keep you logged into
          the site.
        </p>
        <p>
          This site does not use other forms of traffic or analytical /
          performance cookies.
        </p>
        
        <Footer></Footer>
      </div>
      
    );
  }
}

export default Privacy;
