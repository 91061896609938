import React from "react";
import { Route } from "react-router-dom";
import "./App.css";
import Main from "./components/Main.jsx";
import Callback from "./components/Callback.jsx";
import Privacy from "./components/Privacy.jsx";

function App() {
  return (
    <React.Fragment>
      <Route path="/callback" component={Callback} />
      <Route path="/privacy" component={Privacy} />
      <Route path="/" exact component={Main} />
    </React.Fragment>
  );
}

export default App;
